<template>
    <div class="clientcabin clientcabin-blog">
        <v-container class="black--text">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/cost-to-submit/1.png"
                        width="100%"
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        Forbes, TechCrunch & Wired Article Coverage | Cost to
                        Submit & Is it Worth It?
                    </p>
                    <p>
                        Sometimes people ask us if we can get them on TechCrunch
                        or sites like Wired – and we could, but we don’t.
                    </p>
                    <p>
                        We don’t offer that as for most companies there is no
                        ROI, and it’s not unusual for PR companies offering
                        coverage on sites like TechCrunch or Wired to charge
                        $10,000 or more.
                    </p>
                    <p>
                        Going direct for a sponsored post is pricey too… the
                        2019 media kit listed a Techcrunch tweet ‘starting at
                        $7,500’.
                    </p>
                    <p>
                        If you’re not paying for a labeled sponsored post
                        directly to TechCrunch, then your 5-figure payment is
                        basically paying someone who has an existing
                        relationship with the journalist… and for some
                        publications, backhanders may be involved.
                    </p>
                    <p>
                        (We don’t know what happens with any given site, but we
                        can tell you this practice of backhanders is very
                        common.)
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/cost-to-submit/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Being featured on big media is costly – perhaps too
                        costly for the average small business.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Paying To Get Published
                    </p>
                    <p>
                        But regardless of the high prices, and often a lack of
                        traffic that comes with it, it still remains a popular
                        practice to pay to prove you’re a legit company.
                    </p>
                    <p>
                        A site like Techcrunch has a lot of brand value, and is
                        something SaaS and tech owners want to have for status –
                        so it’s overly expensive and harder.
                    </p>
                    <p>
                        Bear in mind there are tech companies with hundreds of
                        millions to billions in funding – so dropping $100k to
                        get 5 big-name sites to mention their company at the
                        start is not uncommon. So why would these influential
                        news sites (and the people involved) do it for less as
                        demand is super high, and scarcity – very high?
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/cost-to-submit/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Pitching news to journalists is always an option, but
                        you need to have a great story and decent (at the very
                        least) writing skills (
                        <a
                            href="https://unsplash.com/photos/person-using-imac-Imc-IoZDMXc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
                            target="_blank"
                        >
                            photo courtesy of Austin Distel on Unsplash
                        </a>
                        ).
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Pitching Newsworthy Stories
                    </p>
                    <p>
                        And of course in the end sites like TechCrunch want
                        something newsworthy, so you can just pitch directly.
                        Unfortunately, chances are you’d be ignored unless you
                        have big actual news – and even then no guarantee anyone
                        will even see it, just because of the noise. So it’s
                        very hit-and-miss, and what newsworthy stuff gets
                        covered is selective.
                    </p>
                    <p>
                        For example, TechCrunch often writes three times a day
                        about PerplexityAI, because they have been funded and
                        are arguably more newsworthy as a platform… but at the
                        same time they have never covered Semrush (except for
                        paid placement or casual mention) – a company that’s
                        actually profitable and is stock-listed, with a $1.7B
                        market cap (both are equally as popular as platforms at
                        the time of writing).
                    </p>
                    <p>
                        That at least seems worth an article and is newsworthy,
                        but maybe they did not pay enough or know the right
                        person…
                    </p>
                    <p>
                        So being newsworthy is only part of it, unless what you
                        do is ridiculously newsworthy.
                    </p>
                    <p>
                        If you have a great story it’s worth emailing a bunch of
                        people who write for tech sites in general or using a
                        pitchbook/muckrack to send a quick outline. This can
                        work, but who picks you up can be quite random, and
                        sometimes you put in a huge effort only to literally get
                        no coverage – or you get coverage, but it brings no
                        traffic or sales and is just an ego boost for your
                        brand.
                    </p>
                    <p>
                        The key is generally being newsworthy – and then that’s
                        just traditional public relations, the way Elon Musk
                        does it, for instance. But that’s not worth it for most
                        businesses, as direct marketing is a lower-hanging
                        fruit, and getting actual news coverage can be
                        hit-and-miss, or just not worth the cost.
                    </p>
                    <p>
                        If you have a good overall SEO and content strategy then
                        exposure on a major site can be worth it for the boost
                        to Google traffic. However, you also have to consider
                        your ROI as some sites can cost $10,000, while similar
                        sites could cost $500, and so the ROI can be very
                        different.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/reasons/cost-to-submit/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        If you’re smart about getting your brand featured on the
                        media platforms that matter, you could actually be doing
                        it as easily as uploading content on social media (
                        <a
                            href="https://unsplash.com/photos/person-using-imac-Imc-IoZDMXc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"
                            target="_blank"
                        >
                            photo courtesy of Austin Distel on Unsplash
                        </a>
                        ).
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Media Coverage, The Smart Way
                    </p>
                    <p>
                        In the end, traditional media is more of an ego rub and
                        isn’t needed at all for a company to succeed. Alex
                        Hormozi has not been covered once on TechCrunch – but is
                        at 9 figures and involved in multiple successful
                        companies, including one SaaS that sold.
                    </p>
                    <p>
                        That’s why we’re a lot more selective about picking
                        publications to publish to, going for those which
                        actually give a business ROI, and combining that with
                        leveraging content marketing and Google for long-term
                        traffic and sales growth.
                    </p>
                    <p>
                        <router-link
                            to="/info"
                            class="d-inline-block font-weight-bold"
                        >
                            Book an appointment
                        </router-link>
                        with our team to learn how we can get your content on
                        publications that can give your business ROI.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class CostToSubmit extends Vue {}
</script>

<style lang="scss" scoped>
.clientcabin-blog::v-deep {
    background-color: $white;
    color: $black;

    table {
        border-collapse: collapse;

        tr > td:first-child {
            font-weight: 600;
        }

        td,
        th {
            padding: 5px 8px;
            vertical-align: top;
            border: 2px solid #eee;
        }
    }
}
</style>
